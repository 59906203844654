import React, {useState} from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';
import Stack from 'react-bootstrap/Stack';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/about.css';
import '../../shared/css/typography.css';
import Ninh from '../../assets/team/ninh.jpeg';
import Beatrice from '../../assets/team/beatrices.png';

import Paul from '../../assets/team/Paul.png';

import kelvin from '../../assets/team/kelvin.png';

import Roshni from '../../assets/team/Roshnii.png';

import bolade from '../../assets/team/boladee.png';

import Justin from '../../assets/team/justin.png';

import RinaOtey from '../../assets/team/RinaOtey.png';


function TeamSection() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="About">
      <Container fluid>
        <Row>
          <Col md={12} className="mt-5 mb-2 ps-5">
            <h2 className="mb-4 bold">Meet the driving force of Snapbrillia</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3 mb-2 ps-5">
            <Row className="mb-5">
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Ninh Tran"
                      src={Ninh}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Ninh Tran</h4></div>
                    <div>
                      <p className="text-muted small" onClick={handleShow}>CEO and Co-Founder</p>
                    </div>
                  </Stack></li>
                </ul>
                <Row className="mt-0">
                  <Col md={12}>
                    <Figure.Caption>
                        <em>"As a child I wanted to be, a garbage truck driver"</em>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Beatrice Thelisma"
                      src={Beatrice}
                      roundedCircle
                      className="d-inline"
                      />
                    </Figure>
                  </li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Beatrice Thelisma</h4></div>
                    <div><p className="text-muted small">CTO and Co-Founder</p></div>
                  </Stack></li>
                </ul>
                <Row>
                  <Col md={12}>
                    <Figure.Caption>
                      <em>"As a child I wanted to be, a business woman"</em>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
                <Col md={4}>
                <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><Figure className="d-inline">
                    <Figure.Image
                      width={80}
                      height={80}
                      alt="Paul Albanese"
                      src={Paul}
                      roundedCircle
                      className="d-inline"
                    />
                  </Figure></li>
                  <li className="list-group-item"><Stack gap={3} className="d-inline">
                    <div><h4>Paul Ritterbush</h4></div>
                    <div><p className="text-muted small">Development Team</p></div>
                  </Stack></li>
                </ul>
                <Row>
                  <Col md={12}>
                    <Figure.Caption>
                      <a href="https://youkevin.be/HPPj6viIBmU" target="_blank" rel="noreferrer" className="text-decoration-none hidden-link">
                        <em>"As a child I wanted to be, a jedi"</em>
                      </a>
                    </Figure.Caption>
                  </Col>
                </Row>
                </Col>
            </Row>
            </Col>
           
               
               
          
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="kelvin Kerimu"
                          src={kelvin}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>kelvin Kerimu</h4></div>
                        <div><p className="text-muted small">Development Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Bolade Victory"
                          src={bolade}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Bolade Victory</h4></div>
                        <div><p className="text-muted small">Project Management Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                  </Col>

              
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="RinaOtey"
                          src={RinaOtey}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Rina Otey</h4></div>
                        <div><p className="text-muted small">Project Management Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <a href="https://d3js.org/" target="_blank" rel="noreferrer" className="text-decoration-none hidden-link">
                            <em>"As a child I wanted to be, a d3 master"</em>
                          </a>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>                
                    <Col md={4}>
                    <ul className="list-group list-group-horizontal">
                      <li className="list-group-item"><Figure className="d-inline">
                        <Figure.Image
                          width={80}
                          height={80}
                          alt="Justin Sabal"
                          src={Justin}
                          roundedCircle
                          className="d-inline"
                        />
                      </Figure></li>
                      <li className="list-group-item"><Stack gap={3} className="d-inline">
                        <div><h4>Justin Sabal</h4></div>
                        <div><p className="text-muted small">Product Team</p></div>
                      </Stack></li>
                    </ul>
                    <Row>
                      <Col md={12}>
                        <Figure.Caption>
                          <em>"As a child I wanted to be, a doctor"</em>
                        </Figure.Caption>
                      </Col>
                    </Row>
                    </Col>
                </Row>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Congrakevinlations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you found the hidden egg! However, we moved it and forgot where we put it.  Better luck next time!</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </Container>
    </div>
  );
}

export default TeamSection;